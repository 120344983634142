<template>
  <q-page class="productListing">
    <template v-if="!loadingCategory">
      <CategoryHeader
        :category="currentCategory"
        :loading="loadingCategory"
        :sortVal="categoryFilters.sortBy"
        @sorby-modified="sortByModified"
        @changeView="changeView"
      />
      <Listing
        v-if="currentCategory"
        :category="currentCategory"
        productsBy="CT"
        :blnShowCategoryTree="true"
        :sortBy="categoryFilters.sortBy"
        :pageSize="categoryFilters.pageSize"
        :isListView="!isMobile ? categoryFilters.isListView : false"
        @sorby-modified="sortByModified"
      />
      <EmptyComponent
        v-else
        image="images/empty-product.png"
        btnText="Continue Shopping"
        to="/"
      >
        <p>
          Currently, this category is not available for the selected location.
          Please select another location.
        </p>
      </EmptyComponent>
    </template>
    <ProductListingSkeleton
      v-else
      :showHeaderLoader="true"
      :showFilterBarLoader="true"
      :showListingLoader="true"
      :isListView="!isMobile ? categoryFilters.isListView : false"
    />
  </q-page>
</template>

<script>
import CategoryHeader from 'components/category/CategoryHeader'
import Listing from 'components/product/Listing'
import { mapGetters } from 'vuex'
import {
  setAVSessionID,
  removeAVSessionID,
  tempCacheStorage,
} from 'src/boot/axios'

export default {
  name: 'CategoryPage',
  /* preFetch({ store, currentRoute, redirect }) {
    let { seoName } = currentRoute.params,
      locationId = store.getters['persisted/currentLocationId'],
      promise

    // store.commit('category/SET_LOADING_CATEGORY', true)
    store.commit('product/RESET_PAGINATION')

    promise = store
      .dispatch('category/getCategory', {
        seoName: seoName,
        locationId: locationId,
      })
      .then((currentCategory) => {
        // if (!currentCategory) {
        // store.commit('category/SET_LOADING_CATEGORY', false)
        // redirect({
        //   name: 'Home',
        // })
        // }
      })

    if (process.env.SERVER) return promise
  }, */
  components: {
    CategoryHeader,
    Listing,
    ProductListingSkeleton: () => import('components/ProductListingSkeleton'),
  },
  meta() {
    return {
      title: this.title,
      ...this.commonMeta,
    }
  },
  data() {
    return {
      sortBy: 'Price Low to High',
      isListView: false,
    }
  },
  computed: {
    title() {
      return (
        this.$options?.filters?.titleCase(
          this.currentCategory?.categoryName || ''
        ) || ''
      )
    },
    ...mapGetters('category', ['currentCategory', 'loadingCategory']),
    ...mapGetters('persisted', [
      'eaivPersistedSessionId',
      'eaivPersistedVerificationSuccess',
      // 'showEAIVPage',
    ]),
    ...mapGetters('persistedLocal', ['getFilterByPage']),
    ...mapGetters('ageVerification', [
      'isVerificationFail',
      'showVeirificationPopup',
    ]),
    ...mapGetters('customer', ['customer']),
    eaivSessionId() {
      return this.$route.query?.sessionId
    },
    categoryFilters() {
      return {
        ...this.getFilterByPage('category'),
        pageSize: 20,
      }
    },
  },
  methods: {
    changeView(isListView) {
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: 'category',
        filter: {
          ...this.categoryFilters,
          isListView,
        },
      })
    },
    sortByModified(sortBy) {
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: 'category',
        filter: {
          ...this.categoryFilters,
          sortBy,
        },
      })
    },
    removeQueryParams(eaivSessionId, isSuccess) {
      if (isSuccess) setAVSessionID(eaivSessionId)
      else {
        this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
        removeAVSessionID()
      }

      this.$store.commit('ageVerification/SET_VERIFICATION_STATUS', isSuccess)
      // remove sessionId from URL
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query.sessionId
      this.$router.replace({ query, params })
    },
    onEaivSuccess({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'Category') {
        if (!eaivSessionId || !routeName) return
        if (!isPopup) this.removeQueryParams(eaivSessionId, true)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.showSuccess('Age Verified Successfully.')
        }, 500)

        if (this.isCordova) {
          this.$store
            .dispatch('category/getCategory', {
              seoName: this.$route.params.seoName,
              locationId: this.$store.getters['persisted/currentLocationId'],
            })
            .then((currentCategory) => {
              if (!currentCategory)
                this.$router.push({
                  name: 'Home',
                })
            })
        } else if (isPopup) {
          tempCacheStorage.clear()
          this.$store
            .dispatch('category/getCategory', {
              seoName: this.$route.params.seoName,
              locationId: this.$store.getters['persisted/currentLocationId'],
            })
            .then((currentCategory) => {
              if (!currentCategory)
                this.$router.push({
                  name: 'Home',
                })
            })
        }
      }
    },
    onEaivFail({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'Category') {
        if (!eaivSessionId || !routeName) return
        if (!isPopup) this.removeQueryParams(eaivSessionId, false)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.changeDialogState({
            dialog: 'VerificationFailDialog',
            val: true,
            properties: {},
          })
        }, 500)

        if (this.isCordova) {
          this.$store
            .dispatch('category/getCategory', {
              seoName: this.$route.params.seoName,
              locationId: this.$store.getters['persisted/currentLocationId'],
            })
            .then((currentCategory) => {
              if (!currentCategory)
                this.$router.push({
                  name: 'Home',
                })
            })
        } else if (isPopup) {
          tempCacheStorage.clear()
          this.$store
            .dispatch('category/getCategory', {
              seoName: this.$route.params.seoName,
              locationId: this.$store.getters['persisted/currentLocationId'],
            })
            .then((currentCategory) => {
              if (!currentCategory)
                this.$router.push({
                  name: 'Home',
                })
            })
        }
      }
    },
  },
  created() {
    this.$root.$on('eaivVerificationSuccess', this.onEaivSuccess)
    this.$root.$on('eaivVerificationFailed', this.onEaivFail)

    // Browse the products on successful verification
    /* if (
      !this.isCordova &&
      !this.showEAIVPage &&
      this.businessConfigurations?.isStoreAgeRestricted &&
      this.businessConfigurations?.enableAgeRestrictedBrowsing &&
      !this.loadingCategory &&
      !this.currentCategory?.seoName
    ) {
      let { seoName } = this.$route.params,
        locationId = this.$store.getters['persisted/currentLocationId']
      this.$store
        .dispatch('category/getCategory', {
          seoName: seoName,
          locationId: locationId,
        })
        .then((currentCategory) => {
          if (!currentCategory)
            this.$router.push({
              name: 'Home',
            })
        })
    } */
  },
  mounted() {
    // prefetch code
    let { seoName } = this.$route.params,
      locationId = this.$store.getters['persisted/currentLocationId']

    this.$store
      .dispatch('category/getCategory', {
        seoName: seoName,
        locationId: locationId,
      })
      .then((currentCategory) => {
        if (!currentCategory)
          this.$store.commit('category/SET_LOADING_CATEGORY', false)
      })
      .catch((error) => {
        console.log('error', error)
      })
    // prefetch code
  },
  beforeRouteUpdate(_, __, next) {
    this.$store.commit('category/SET_CATEGORY')
    this.$store.commit('category/SET_LOADING_CATEGORY', true)
    next()
  },
  beforeRouteLeave(_, __, next) {
    this.$store.commit('category/SET_CATEGORY')
    this.$store.commit('product/RESET_PAGINATION')
    next()
  },
}
</script>

<style lang="scss"></style>
