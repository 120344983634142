<template>
  <div :class="['page-title', loading ? 'q-loader-noicon' : '']">
    <template v-if="loading">
      <div class="row full-width">
        <div class="col-6">
          <q-skeleton width="200px" height="20px" />
          <q-skeleton type="text" width="160px" height="20px" class="q-mt-sm" />
        </div>
      </div>
    </template>
    <template v-else-if="!loading && category">
      <div class="page-title--left" v-if="!isMobile">
        <h1> {{ $options.filters.titleCase(category.categoryName) }} </h1>
        <q-breadcrumbs>
          <q-breadcrumbs-el
            label="Home"
            :to="{
              name: 'Home',
            }"
          />
          <q-breadcrumbs-el
            v-for="(category, index) of categoryHirachaies"
            :key="index"
            :label="$options.filters.titleCase(category.name)"
            :to="{
              name: 'Category',
              params: {
                seoName: category.seoName,
              },
            }"
          />
        </q-breadcrumbs>
      </div>
      <div class="page-title--right q-ml-auto" v-if="!isMobile">
        <div class="sorting flex items-center">
          <span class="sorting-label">Sort :</span>
          <q-select
            dense
            borderless
            v-model="sortBy"
            @input="sortByModified"
            :options="sortByOptions"
            emit-value
            map-options
            popup-content-class="categorysort"
            @popup-show="sortByPopupShow"
            @popup-hide="sortByPopupHide"
          >
            <template v-slot:option="{ itemProps, opt }">
              <q-item
                v-bind="itemProps"
                @click="updateSelected(opt)"
                v-close-popup
              >
                <q-item-section>
                  <q-item-label v-html="opt.label" />
                </q-item-section>
                <q-item-section side v-if="isMobile">
                  <q-radio
                    :value="sortBy"
                    :val="opt.value"
                    @input="updateSelected(opt)"
                    v-close-popup
                  />
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
        <ProductLayoutIcons
          v-if="!isMobile"
          @changeView="(val) => this.$emit('changeView', val)"
          :isListView="categoryFilters.isListView"
        />
      </div>
    </template>
  </div>
</template>

<script>
import ProductLayoutIcons from 'components/common/ProductLayoutIcons';
import { mapGetters } from 'vuex';

export default {
  name: 'CategoryHeader',
  components: {
    ProductLayoutIcons,
  },
  props: {
    category: {
      type: Object,
      default: () => {
        return {}
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
    sortVal: {
      type: String,
      default: 'Price Low to High',
    },
  },
  data() {
    return {
      sortBy: this.sortVal,
    }
  },
  computed: {
    ...mapGetters('product', ['sortByOptions']),
    ...mapGetters('category', ['getCategoryTreeInfoById', 'getCategoryById']),
    ...mapGetters('persistedLocal', ['getFilterByPage']),
    categoryFilters() {
      return this.getFilterByPage('category')
    },
    categoryHirachaies() {
      let tree = this.getCategoryTreeInfoById(this.category.categoryID),
        hirachaies = []

      if (tree) {
        let selectedKeys = [...tree.parentsOfSelected, this.category.categoryID]
        for (let key of selectedKeys) {
          let category = this.getCategoryById(key)
          hirachaies.push({
            id: category.categoryID,
            name: category.categoryName,
            seoName: category.seoName,
          })
        }
      }
      return hirachaies
    },
  },
  methods: {
    sortByModified(value) {
      this.$emit('sorby-modified', this.sortBy)
    },
    updateSelected(opt) {
      this.sortBy = opt.value
      this.sortByModified()
    },
    sortByPopupShow() {
      document
        .getElementsByTagName('body')[0]
        .classList.add('sortingDialogOpen')
    },
    sortByPopupHide() {
      document
        .getElementsByTagName('body')[0]
        .classList.remove('sortingDialogOpen')
    },
  },
}
</script>
<style lang="scss">
@media (max-width: 1024px) {
  .sortingDialogOpen {
    .q-dialog__inner.flex-center {
      align-items: flex-end;
      padding: 0;
      .q-select__dialog {
        width: 100vw !important;
        max-width: 100vw !important;
        border-radius: 0;
      }
    }
  }
}
</style>
