var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['page-title', _vm.loading ? 'q-loader-noicon' : '']},[(_vm.loading)?[_c('div',{staticClass:"row full-width"},[_c('div',{staticClass:"col-6"},[_c('q-skeleton',{attrs:{"width":"200px","height":"20px"}}),_c('q-skeleton',{staticClass:"q-mt-sm",attrs:{"type":"text","width":"160px","height":"20px"}})],1)])]:(!_vm.loading && _vm.category)?[(!_vm.isMobile)?_c('div',{staticClass:"page-title--left"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$options.filters.titleCase(_vm.category.categoryName))+" ")]),_c('q-breadcrumbs',[_c('q-breadcrumbs-el',{attrs:{"label":"Home","to":{
            name: 'Home',
          }}}),_vm._l((_vm.categoryHirachaies),function(category,index){return _c('q-breadcrumbs-el',{key:index,attrs:{"label":_vm.$options.filters.titleCase(category.name),"to":{
            name: 'Category',
            params: {
              seoName: category.seoName,
            },
          }}})})],2)],1):_vm._e(),(!_vm.isMobile)?_c('div',{staticClass:"page-title--right q-ml-auto"},[_c('div',{staticClass:"sorting flex items-center"},[_c('span',{staticClass:"sorting-label"},[_vm._v("Sort :")]),_c('q-select',{attrs:{"dense":"","borderless":"","options":_vm.sortByOptions,"emit-value":"","map-options":"","popup-content-class":"categorysort"},on:{"input":_vm.sortByModified,"popup-show":_vm.sortByPopupShow,"popup-hide":_vm.sortByPopupHide},scopedSlots:_vm._u([{key:"option",fn:function({ itemProps, opt }){return [_c('q-item',_vm._b({directives:[{name:"close-popup",rawName:"v-close-popup"}],on:{"click":function($event){return _vm.updateSelected(opt)}}},'q-item',itemProps,false),[_c('q-item-section',[_c('q-item-label',{domProps:{"innerHTML":_vm._s(opt.label)}})],1),(_vm.isMobile)?_c('q-item-section',{attrs:{"side":""}},[_c('q-radio',{directives:[{name:"close-popup",rawName:"v-close-popup"}],attrs:{"value":_vm.sortBy,"val":opt.value},on:{"input":function($event){return _vm.updateSelected(opt)}}})],1):_vm._e()],1)]}}],null,false,2836317375),model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}})],1),(!_vm.isMobile)?_c('ProductLayoutIcons',{attrs:{"isListView":_vm.categoryFilters.isListView},on:{"changeView":(val) => this.$emit('changeView', val)}}):_vm._e()],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }