import { render, staticRenderFns } from "./CategoryHeader.vue?vue&type=template&id=5725dac6"
import script from "./CategoryHeader.vue?vue&type=script&lang=js"
export * from "./CategoryHeader.vue?vue&type=script&lang=js"
import style0 from "./CategoryHeader.vue?vue&type=style&index=0&id=5725dac6&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QSkeleton,QBreadcrumbs,QBreadcrumbsEl,QSelect,QItem,QItemSection,QItemLabel,QRadio,QDialog});qInstall(component, 'directives', {ClosePopup});
